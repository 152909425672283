<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="55%"
    @close="closeCallback(false)">
    <template slot="title">
      <h3 class="i-title"><i class="el-icon-s-order mr-1"></i>用户协议</h3>
    </template>
    <el-input
      type="textarea"
      autosize
      :readOnly="true"
      placeholder="请输入内容"
      v-model="textarea">
    </el-input>
    <div style="text-align: center;margin-top: 10px">
      <el-button @click="closeCallback(false)">取消</el-button>
      <el-button type="primary" :disabled="confirmAck" @click="closeCallback(true)">确定{{this.time}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    components: {
    },
    data () {
      return {
        visible: false,
        confirmAck: true,
        time: '',
        timer: null,
        textarea:''
      }
    },
    created () {
      // this.textarea = this.readFile('/用户协议.txt')
      this.textarea = '本平台（www.gongsibao.com）依据以下条件和条款为您提供服务，请仔细阅读并遵守。\n' +
        '\n' +
        '若您已经注册为本平台用户，即表示您已充分阅读、理解并同意自己与本平台订立本协议，且您自愿受本协议的条款约束。本平台有权随时变更本协议并在本平台网站上予以公告。经修订的条款一经在网站公布后，立即自动生效。如您不同意相关变更，必须停止使用本平台。本协议内容包括协议正文及所有本平台已经发布的各类规则。所有规则为本协议不可分割的一部分，与本协议正文具有同等法律效力。一旦您继续使用本平台，则表示您已接受并自愿遵守经修订后的条款。\n' +
        '\n' +
        '第一条 用户资格\n' +
        '\n' +
        '（一）用户需具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且本平台有权注销（永久冻结）您的账户，并向您及您的监护人索偿。如您代表一家公司或其他法律主体在本平台站登记，则您声明和保证，您有权使该公司或该法律主体受本协议的约束。\n' +
        '\n' +
        '（二）用户需要提供联系电话，并提供真实姓名或名称。\n' +
        '\n' +
        '（三）本平台用户须承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性。\n' +
        '\n' +
        '第二条 用户的权利和义务\n' +
        '\n' +
        '（一）用户有权根据本协议及本平台发布的相关规则，利用本平台相关功能及有权享受本平台提供的其他有关资讯及信息服务。\n' +
        '\n' +
        '（二）用户须自行保管自己的用户账号和密码，且须对在用户账号密码下发生的所有活动（包括但不限于网上点击同意各类协议、规则、进行下单支付等）承担责任。用户有权根据需要更改登录和账户密码。因用户的过错导致的任何损失由用户自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，用户使用的计算机被他人侵入。\n' +
        '\n' +
        '（三）用户应当向本平台提供真实准确的注册信息，包括但不限于真实姓名、身份证认证、联系电话、地址、邮政编码等。保证本平台可以通过上述联系方式与自己进行联系。同时，用户也应当在相关资料实际变更时及时更新有关注册资料。\n' +
        '\n' +
        '（四）用户在本平台注册的账号名称，不得有下列情形：\n' +
        '\n' +
        '1、违反宪法或法律法规规定的；\n' +
        '\n' +
        '2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；\n' +
        '\n' +
        '3、损害国家荣誉和利益的，损害公共利益的；\n' +
        '\n' +
        '4、煽动民族仇恨、民族歧视，破坏民族团结的；\n' +
        '\n' +
        '5、破坏国家宗教政策，宣扬邪教和封建迷信的；\n' +
        '\n' +
        '6、散布谣言，扰乱社会秩序，破坏社会稳定的；\n' +
        '\n' +
        '7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；\n' +
        '\n' +
        '8、侮辱或者诽谤他人，侵害他人合法权益的；\n' +
        '\n' +
        '9、含有法律、行政法规禁止的其他内容的。\n' +
        '\n' +
        '（五）用户不得以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息。\n' +
        '\n' +
        '（六）用户不得冒用他人、知名机构或社会名人等姓名或名称注册账号。\n' +
        '\n' +
        '（七）用户不得以任何形式擅自转让或授权他人使用自己在本平台的用户帐号；\n' +
        '\n' +
        '（八）用户不应采取不正当手段（包括但不限于虚假订单、互换好评等方式）提高自身或他人信用度，或采用不正当手段恶意评价其他用户，降低其他用户信用度；\n' +
        '\n' +
        '（九）用户承诺自己在使用本平台实施的所有行为遵守法律、法规、行政规章和本平台的相关规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，用户将以自己的名义独立承担相应的法律责任；\n' +
        '\n' +
        '（十）未经本平台书面允许，用户不得将本平台的任何资料以及所展示的任何信息作商业性利用（包括但不限于以复制、修改、翻译等形式制作衍生作品、分发或公开展示）；\n' +
        '\n' +
        '（十一）用户不得使用以下方式登录网站或破坏网站所提供的服务：\n' +
        '\n' +
        '1、以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件或其它自动方式访问或登录本平台；\n' +
        '\n' +
        '2、通过任何方式对本公司内部结构造成或可能造成不合理或不合比例的重大负荷的行为；\n' +
        '\n' +
        '3、通过任何方式干扰或试图干扰网站的正常工作或网站上进行的任何活动。\n' +
        '\n' +
        '（十二）用户同意接收来自本平台的信息，包括但不限于活动信息、交易信息、促销信息等。\n' +
        '\n' +
        '（十三）用户了解并同意，如用户在本平台成功注册，并成功完成实名认证。\n' +
        '\n' +
        '第三条 本平台的权利和义务\n' +
        '\n' +
        '（一）本平台仅为用户提供产品展示，用户需求确认等服务；\n' +
        '\n' +
        '（二）本平台有义务在现有技术水平的基础上努力确保整个网上交流平台的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交流活动的顺利进行；\n' +
        '\n' +
        '（三）本平台有义务对用户在注册使用本平台所遇到的与注册有关的问题及反映的情况及时作出回复；\n' +
        '\n' +
        '（四）本平台有权对用户的注册资料进行审查，对存在任何问题或怀疑的注册资料，本平台有权发出通知询问用户并要求用户做出解释、改正；用户如不改正的，本平台有权不予批准注册。\n' +
        '\n' +
        '（五）因本平台的特殊性，本平台不承担对所有用户的注册信息进行实质审查的义务，但如发生以下情形，本平台有权无需征得用户的同意限制用户的活动、向用户核实有关资料、发出警告通知、暂时中止、无限期中止及拒绝向该用户提供服务：\n' +
        '\n' +
        '1、用户以非自然人名义进行认证之后认证主体自行注销或者经有权机关吊销或撤销的；\n' +
        '\n' +
        '2、用户违反本协议或因被提及而纳入本协议的相关规则；\n' +
        '\n' +
        '3、存在用户或其他第三方通知本平台，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据，而本平台无法联系到该用户核证或验证该用户向本平台提供的任何资料；\n' +
        '\n' +
        '4、存在用户或其他第三方通知本平台，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据。本平台以普通非专业人员的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为可能对他方或本平台造成财务损失或承担法律责任；\n' +
        '\n' +
        '（六）根据国家法律、法规、行政规章规定、本协议的内容和本平台所掌握的事实依据，可以认定该用户存在违法或违反本协议行为以及在本平台交易平台上的其他不当行为，本平台有权无需征得用户的同意在本平台交易平台及所在网站上以网络发布形式公布该用户的违法行为，并有权随时作出删除相关信息、终止服务提供等处理；\n' +
        '\n' +
        '（七）本平台有权在不通知用户的前提下，删除或采取其他限制性措施处理下列信息：包括但不限于以规避费用为目的；以炒作信用为目的；存在欺诈等恶意或虚假内容；与网上交易无关或不是以交易为目的；存在恶意竞价或其他试图扰乱正常交易秩序因素；违反公共利益或可能严重损害本平台和其他用户合法利益。\n' +
        '\n' +
        '第四条 服务的中止和终止\n' +
        '\n' +
        '（一）本平台可自行全权决定以任何理由（包括但不限于本平台认为用户已违反本协议及相关规则的字面意义和精神）终止对用户的服务，并有权在两年内保存用户在本平台的全部资料（包括但不限于用户信息、产品信息、交易信息等）。同时本平台可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供全部或部分服务。服务终止后，本平台没有义务为用户保留原账户中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方；\n' +
        '\n' +
        '（二）若用户申请终止本平台服务，需经本平台审核同意，方可解除与本平台的协议关系，但本平台仍保留下列权利：\n' +
        '\n' +
        '1、本平台有权在法律、法规、行政规章规定的时间内保留该用户的资料,包括但不限于以前的用户资料、交易记录等;\n' +
        '\n' +
        '2、若终止服务之前，该用户在本平台交易平台上存在违法行为或违反本协议的行为，本平台仍可行使本协议所规定的权利。\n' +
        '\n' +
        '（三）用户存在下列情况，本平台可以终止向该用户提供服务：\n' +
        '\n' +
        '1、在用户违反本协议及相关规则规定时，本平台有权终止向该用户提供服务。本平台将在中断服务时通知用户。但该用户在被本平台终止提供服务后，再一次直接或间接或以他人名义注册为本平台用户的，本平台有权再次单方面终止为该用户提供服务；\n' +
        '\n' +
        '2、本平台发现用户注册资料中主要内容是虚假的，本平台有权随时终止为该用户提供服务；\n' +
        '\n' +
        '3、本协议终止或更新时，用户未确认新的协议的；\n' +
        '\n' +
        '4、其它本平台认为需终止服务的情况。\n' +
        '\n' +
        '第五条 本平台的责任范围\n' +
        '\n' +
        '当用户接受该协议时，用户应当明确了解并同意：\n' +
        '\n' +
        '（一）本平台不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。本平台是在现有技术基础上提供的服务。本平台不保证以下事项∶\n' +
        '\n' +
        '1、本平台将符合所有用户的要求；\n' +
        '\n' +
        '2、本平台不受干扰、能够及时提供、安全可靠或免于出错；\n' +
        '\n' +
        '3、本服务使用权的取得结果是正确或可靠的。\n' +
        '\n' +
        '（二）是否经由本平台下载或取得任何资料，由用户自行考虑、衡量并且自负风险，因下载任何资料而导致用户电脑系统的任何损坏或资料流失，用户应负完全责任。希望用户在使用本平台时，小心谨慎并运用常识；\n' +
        '\n' +
        '（三）用户经由本平台取得的建议和资讯，无论其形式或表现，绝不构成本协议未明示规定的任何保证；\n' +
        '\n' +
        '（四）基于以下原因而造成的利润、商誉、使用、资料损失或其它无形损失，本平台不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿（即使本平台已被告知前款赔偿的可能性）\n' +
        '\n' +
        '1、本平台的使用或无法使用；\n' +
        '\n' +
        '2、用户的传输或资料遭到未获授权的存取或变更；\n' +
        '\n' +
        '3、本平台其它相关事宜。\n' +
        '\n' +
        '（五）本平台提供与其它互联网上的网站或资源的链接，用户可能会因此连结至其它运营商经营的网站，但不表示本平台与这些运营商有任何关系。其它运营商经营的网站均由各经营者自行负责，不属于本平台控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，本平台亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，本平台不负任何直接或间接的责任。\n' +
        '\n' +
        '第六条 本平台知识产权\n' +
        '\n' +
        '（一）本平台所使用的任何相关软件、程序、内容，包括但不限于作品、图片、档案、资料、网站构架、网站版面的安排、网页设计、经由本平台向用户呈现的广告或资讯，均由本平台依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。未经本平台或权利人明示授权，用户保证不修改、出租、出借、出售、散布本平台及本平台所使用的上述任何资料和资源，或根据上述资料和资源制作成任何种类产品；\n' +
        '\n' +
        '（二）本平台授予用户不可转移及非专属的使用权，使用户可以通过单机计算机使用本平台的目标代码（以下称“软件”），但用户不得且不得允许任何第三方复制、修改、创作衍生作品、进行还原工程、反向组译，或以其它方式破译或试图破译源代码，或出售、转让软件或对软件进行再授权，或以其它方式移转“软件”之任何权利。用户同意不以任何方式修改软件，或使用修改后的软件；\n' +
        '\n' +
        '（三）用户不得经由非本平台所提供的界面使用本平台。\n' +
        '\n' +
        '（四）如果任何第三方侵犯了本平台用户相关的权利，用户同意授权本平台或其指定的代理人代表本平台自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在本平台认为必要的情况下参与共同维权；\n' +
        '\n' +
        '（五）如个人或单位发现本平台上存在侵犯其自身合法权益的内容，请及时与本平台取得联系，并提供具有法律效应的证明材料，以便本平台作出处理。本平台有权根据实际情况删除相关的内容，并追究相关用户的法律责任。给本平台或任何第三方造成损失的，侵权用户应负全部责任；\n' +
        '\n' +
        '（六）未经本平台许可，任何人不得盗链本平台下载资源；不得复制或仿造本站或者在非本平台所属服务器上建立镜像；\n' +
        '\n' +
        '（七）本平台的标识（包括但不限于本平台网址，APP，微信公众号、订阅号等）、源代码、及所有页面的版式设计等，版权归本平台所有。未经授权，不得用于除本平台之外的任何站点；\n' +
        '\n' +
        '（八）当本平台用户的言论和行为侵犯了第三方的著作权或其他权利，责任在于用户本人，本平台不承担任何法律责任。\n' +
        '\n' +
        '第七条 隐私\n' +
        '\n' +
        '（一）信息使用：\n' +
        '\n' +
        '1、本平台不会向任何人出售或出借用户的个人或法人信息，除非事先得到用户得许可；\n' +
        '\n' +
        '2、本平台亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人或法人信息。任何用户如从事上述活动，一经发现，本平台有权立即终止与该用户的用户协议，查封其账号。\n' +
        '\n' +
        '（二）信息披露：\n' +
        '\n' +
        '用户的个人或法人信息将在下述情况下部分或全部被披露：\n' +
        '\n' +
        '1、经用户同意，向第三方披露；\n' +
        '\n' +
        '2、用户在使用本平台过程中涉及到知识产权类纠纷，有他方主张权利的，本平台在审核主张方提交的资料后认为披露用户信息有助于纠纷解决的；\n' +
        '\n' +
        '3、根据法律的有关规定，或者行政、司法机关的要求，向第三方或者行政、司法机关披露；\n' +
        '\n' +
        '4、若用户出现违反中国有关法律或者网站规定的情况，需要向第三方披露；\n' +
        '\n' +
        '5、为提供你所要求的产品和服务，而必须和第三方分享用户的个人或法人信息；\n' +
        '\n' +
        '6、其它本平台根据法律或者网站规定认为合适的披露。\n' +
        '\n' +
        '（三）信息安全：\n' +
        '\n' +
        '1、在使用本平台服务进行网上交易时，请用户妥善保护自己的个人或法人信息，仅在必要的情形下向他人提供；\n' +
        '\n' +
        '2、如果用户发现自己的个人或法人信息泄密，尤其是用户账户或“支付账户管理”账户及密码发生泄露，请用户立即联络本平台客服，以便我们采取相应措施。\n' +
        '\n' +
        '第八条 不可抗力\n' +
        '\n' +
        '因不可抗力或者其他意外事件，使得本协议的履行因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本合同所称之不可抗力意指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。\n' +
        '\n' +
        '第九条 保密\n' +
        '\n' +
        '用户保证在使用本平台过程中所获悉的属于本平台及他方的且无法自公开渠道获得的文件及资料（包括但不限于商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）予以保密。未经该资料和文件的原提供方同意，用户不得向第三方泄露该商业秘密的全部或者部分内容。但法律、法规、行政规章另有规定或者双方另有约定的除外。\n' +
        '\n' +
        '第十条 纠纷解决\n' +
        '\n' +
        '（一）本协议及其规则的有效性、履行和与本协议及其规则效力有关的所有事宜，将受中华人民共和国法律管辖，任何争议仅适用中华人民共和国法律；\n' +
        '\n' +
        '（二）凡因履行本协议及其规则发生的纠纷，各方可协商解决；若协商不成的，各方一致同意提交北京市海淀区人民法院诉讼解决。\n' +
        '\n' +
        '第十一条 解释权\n' +
        '\n' +
        '本平台对本用户协议包括基于本用户协议制定的各项规则拥有最终解释权。'
    },
    methods: {
      // 初始化
      init () {
        this.visible = true
        this.countdown(5)
      },
      closeCallback (refresh) {
        this.visible = false
        this.$emit('closeCallback', refresh)
      },
      countdown (time) {
        if (time < 1) {
          this.confirmAck = false
          this.time = ''
          clearTimeout(this.timer)
          return
        }
        this.confirmAck = true
        this.time = '（'+time+'）'
        this.timer = setTimeout(this.countdown, 1000, --time)
      },
      readFile (filePath) {
        // 创建一个新的xhr对象
        let xhr = null, okStatus = document.location.protocol === 'file' ? 0 : 200
        xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')
        xhr.open('GET', filePath, false)
        xhr.overrideMimeType('text/html;charset=utf-8')
        xhr.send(null)
        return xhr.status === okStatus ? xhr.responseText : null
      }
    }
  }
</script>

<style scoped>
  .i-title {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }
</style>
